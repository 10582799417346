import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback
} from "react";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Peer from "peerjs";
import { getProfile } from "../../services/authservice";
import { getUser } from "../../services/users";
import * as config from "../../utils/config";
import { updateMe, updatePeerId, updateMeetId } from "../videoMeetingSlice";
import {
  updateCurrentUser,
  updateContacts,
  updateConversations,
  updateMessages,
  updateActiveUser,
  updateEntireConversation,
  updateConversationsId,
  updateOnlineUsers,
  updateGroupMessages,
  updateContact
} from "../messengerSlice";
import { getConversation, getMessages, getGroupConversation, getGroupMessages } from "../../services/conversations";
import GroupCallModal from "../../pages/messenger/Chats/GroupCallModal";
import { getConfig } from "../../utils/iceServer";
export const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

const SocketProvider = (props) => {
  const videoMeeting = useSelector((state) => state.videoMeeting);
  const messenger = useSelector((state) => state.messenger);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isGroupcallModal, setIsGroupCallModal] = useState(false);
  const [socket, setSocket] = useState();
  const [isMuted, setIsMuted] = useState(false);
  const [peers, setPeers] = useState({});
  const [streams, setStreams] = useState([]);
  const [localStream, setLocalStream] = useState({});
  const [isMeetingStart, setIsMeetingStart] = useState(false);
  const [isVideoTrack, setIsVideotrack] = useState(true);
  const [me, setMe] = useState({});
  const [group, setGroup] = useState(null);
  const [remoteUser, setRemoteUser] = useState(null);
  const [members, setMembers] = useState([]);
  const [isGroupCall, setGroupCall] = useState(false);
  const [isVideoCall, setIsVideoCall] = useState(false);
  const [isCaller, setIsCaller] = useState(false);

  let peerIdRef = useRef();
  let streamsRef = useRef();
  let myStream = useRef();
  let peersRef = useRef();
  let userStreamRef = useRef();
  let socketRef = useRef();
  let peerServerRef = useRef();
  streamsRef.current = streams;
  socketRef.current = socket;
  peersRef.current = peers;

  const addVideoStream = (streamObj) => {
    let isAlready = false;
    console.log("global state", streamsRef?.current);

    streamsRef?.current?.filter((obj) => {
      console.log("global state", obj);
      if (obj.id == streamObj.id) {
        isAlready = true;
      }
    });

    if (!isAlready) {
      let newStreams = [...streamsRef.current];
      newStreams.push(streamObj);
      setStreams([...newStreams]);
      toast.success(
        `${streamObj.user.contactName.first +
        " " +
        streamObj.user.contactName.last
        } Joined`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const removeVideoStream = (id) => {
    let newStreams = [...streamsRef.current];
    newStreams = newStreams.filter((stream) => {
      console.log("streams filter", stream.user._id, "id", id);
      if (stream.user._id == id) {
        console.log("removed streams", stream.user.username);
        return false;
      } else {
        return true;
      }
    });
    setStreams([...newStreams]);
  };

  const addNewPeer = (userId, call) => {
    setPeers({ ...peersRef.current, [userId]: call });
  };

  const userJoined = useCallback(async ({ peerId, userId }) => {
    const id = getProfile()._id;
    console.log("user joined group conversation", peerId, userId)
    let strm;
    setLocalStream((s) => {
      strm = s;
      return s;
    });

    const call = peerServerRef.current.call(peerId, strm, {
      metadata: { userId: id },
    });
    setIsMeetingStart(true);

    call.on("stream", async (stream1) => {
      const u = await getUser(userId);
      let ref = React.createRef();
      ref.current = stream1;
      addVideoStream({
        user: u.data,
        stream: ref,
        id: stream1.id,
      });
      addNewPeer(userId, call);
    });

    call.on("close", (key) => {
      console.log("call closed", key);
    });
  }, [])

  const startServices = async () => {
    const id = getProfile()._id;
    const res = await getUser(id);
    setMe(res.data);
    dispatch(updateMe(res.data));

    const userLeft = async ({ userId }) => {
      console.log("user left with", userId, streamsRef?.current);
      removeVideoStream(userId);
      const u = await getUser(userId);
      toast.warning(
        `${u.data.contactName.first + " " + u.data.contactName.last} Left`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      console.log("user left", userId, streamsRef?.current, streamsRef?.current.length, isGroupCall);

      setGroupCall((state) => {
        if (state && streamsRef?.current?.length == 0) {
          endMeeting('')
        }

      })

    }



    socketRef?.current?.on("userJoinedMeeting", userJoined);


    socketRef?.current?.on("userJoinedGroupConversation", async (data) => {
      await userJoined(data);
      navigate("/messenger/videomeeting-session");
    });

    socketRef?.current?.on("userLeft", userLeft);

    socketRef?.current?.on("incomingGroupCall", async (data) => {
      console.log("incomming group call", data)
      data.callType == "video" ? setIsVideoCall(true) : setIsVideoCall(false);
      const res = await getUser(data.user);
      setRemoteUser(res.data);
      setGroup(data.groupId);
      setIsGroupCallModal(true);
      await startMeeting(data.groupId.name, true, (data.callType == 'video' ? true : false));
    })
  };

  const startGroupCall = async (members, group, callVideoType) => {
    await startMeeting(group.name, true, (callVideoType == "video" ? true : false));
    setIsCaller(true);
    let newMembers = [];

    for (const m of members) {
      if (m._id !== videoMeeting.me._id) {
        newMembers.push(m._id);
      }
    }
    callVideoType == "video" ? setIsVideoCall(true) : setIsVideoCall(false);
    setMembers(members);
    setGroupCall(true);
    setGroup(group);
    console.log("group call initiated", videoMeeting.me._id, newMembers);
    socketRef?.current.emit("initiateGroupCall", {
      user: videoMeeting.me._id,
      members: newMembers,
      groupId: group,
      conversationId: group._id,
      callType: callVideoType
    });


  }


  const answerGroupCall = async () => {

    setGroupCall(true);
    setIsGroupCallModal(false);
    console.log("answer", group, peerIdRef?.current);
    socketRef?.current.emit("joinGroup", {
      conversationId: group?._id,
      userId: me._id,
      peerId: peerIdRef?.current
    });

  }

  const startMeeting = async (name, isGroupCall = false, callVideoType = true) => {
    dispatch(updateMeetId(name));

    const newStream = await navigator.mediaDevices.getUserMedia({
      video: callVideoType,
      audio: true,
    });

    setLocalStream(newStream);
    myStream.current = newStream;
    // const ice_server = await getConfig();
    const peer = new Peer({
      secure: true,
      host: config.PEER_SERVER_HOST,
      path: config.PEER_SERVER_PATH,
      port: config.PEER_SERVER_PORT,
      // config: ice_server
    });

    peer.on("open", (pid) => {
      dispatch(updatePeerId(pid));
      console.log("connected peer in meeting", pid);
      peerIdRef.current = pid;
      peerServerRef.current = peer;

      peer.on("call", (call) => {
        console.log("i am in peer on call and meta data", call.metadata);
        call.answer(newStream);
        setIsMeetingStart(true);

        call.on("stream", async (stream2) => {
          const u = await getUser(call?.metadata?.userId);
          let ref = React.createRef();
          ref.current = stream2;
          addVideoStream({
            user: u.data,
            stream: ref,
            id: stream2.id,
          });
          addNewPeer(call?.metadata?.userId, call);
        });

        call.on("close", (key) => {
          console.log("call closed", key);
        });
      });

      peer.on("error", (e) => {
        console.log("peer server error", e);
      });


      if (!isGroupCall) {
        console.log("beofre emit", name, peerIdRef.current, videoMeeting.me._id);
        socketRef?.current?.emit("joinMeeting", {
          meetingID: name,
          peerId: peerIdRef.current,
          userId: videoMeeting.me._id,
        });
      }
    });
  };

  const endMeeting = (meetingID) => {

    console.log("end", peersRef.current);
    if (isMeetingStart) {
      for (const key in peersRef.current) {
        peersRef.current[key].close(key);
      }
      setIsMeetingStart(false);
      setPeers({});
      setStreams([]);
    }
    console.log("isGroupCall", isGroupCall)
    if (isGroupCall) {

      console.log("end group call", group?._id, videoMeeting.me._id)

      socketRef?.current?.emit("leaveGroupConversation", {
        conversationId: group?._id,
        userId: videoMeeting.me._id,
      });

      if (isCaller) {
        const messageObj = {
          sender: videoMeeting.me._id,
          receiver: members,
          message: "",
          media: [],
          conversationId: group?._id,
          updatedAt: new Date().toLocaleString(),
          createdAt: new Date().toLocaleString(),
          callInfo: {
            isCall: true,
            callType: isVideoCall.current ? "Video" : "Audio",
            duration: "00" + ":" + "00",
            time: new Date().toLocaleString(),
          },
        };

        console.log("end with", messageObj);
        // updateMessages(messageObj);
        dispatch(
          updateMessages({
            key: messenger.activeUser,
            message: messageObj,
          })
        );
        socket?.emit("sendMessage", messageObj);
      }


    } else {
      socketRef?.current?.emit("leaveMeeting", {
        meetingID: meetingID,
        userId: videoMeeting.me._id,
      });
    }

    setIsMuted(false);
    setIsVideotrack(true);
    setGroup(null);
    peerServerRef?.current?.destroy();

    setLocalStream((state) => {
      if (state) {
        state?.getTracks()?.forEach((track) => {
          track.stop()
          track.release && track.release();
        });
      }
    })

    setLocalStream(null);
    setGroupCall(false);
    navigate(-1);
  };

  const muteStream = () => {
    if (localStream) {
      const audioTracks = localStream.getAudioTracks();
      audioTracks.forEach((track) => {
        track.enabled = false;
        setIsMuted(true);
      });
    }
  };

  // Function to unmute the local stream
  const unmuteStream = () => {
    if (localStream) {
      const audioTracks = localStream.getAudioTracks();
      audioTracks.forEach((track) => {
        track.enabled = true;
        setIsMuted(false);
      });
    }
  };

  const turnOffVideo = () => {
    if (localStream) {
      const videoTracks = localStream.getVideoTracks();
      videoTracks.forEach((track) => {
        track.enabled = false;
        setIsVideotrack(false);
      });
    }
  };

  // Function to turn on the video track
  const turnOnVideo = () => {
    if (localStream) {
      const videoTracks = localStream.getVideoTracks();
      videoTracks.forEach((track) => {
        track.enabled = true;
        setIsVideotrack(true);
      });
    }
  };


  const fetchUpdatedMessages = async () => {
    const token = await getProfile();
    const res = await getUser(token._id);
    const contacts = res.data.contacts;
    const currentUser = res.data;

    dispatch(updateCurrentUser({ ...currentUser, contacts: [] }));
    dispatch(updateContacts(contacts));
    dispatch(updateEntireConversation([]));
    if (currentUser?._id) {
      for (let contact of contacts) {
        const conversation = await getConversation(
          currentUser?._id,
          contact?.user?._id
        );
        console.log("conv is", conversation.data);
        const messages = await getMessages(conversation?.data?._id);
        console.log("messages", messages?.data);
        dispatch(updateConversations([messages?.data]));
      }
    }
  }

  const getMessengerData = async () => {
    const token = await getProfile();
    const res = await getUser(token._id);
    const contacts = res.data.contacts;
    const currentUser = res.data;

    dispatch(updateCurrentUser({ ...currentUser, contacts: [] }));
    // dispatch(updateContacts(contacts));
    console.log("contacts", contacts)
    const unsorted = [];

    if (currentUser?._id) {
      for (let contact of contacts) {
        if(contact?.user){
          const conversation = await getConversation(
            currentUser?._id,
            contact?.user?._id
          );
          console.log("conv is", conversation.data);
          // dispatch(updateConversationsId(conversation?.data?._id));
          const messages = await getMessages(conversation?.data?._id);
          console.log("messages", messages?.data);
          // dispatch(updateConversations([messages?.data]));
          unsorted.push({
            contact: contact,
            conversationId: conversation?.data?._id,
            messages: messages?.data.reverse()
          })
        }
      }
    }

    console.log("groups before")
    const resp = await getGroupConversation();
    console.log("group api res", resp)
    // let groups = [];

    if (resp.status === 200) {
      console.log("groups", resp);
      for (let group of resp.data) {
        const groupContact = { ...group, isGroupChat: true };
        // groups.push(groupContact);
        // dispatch(updateConversationsId(group?._id));
        const groupMesseges = await getGroupMessages(group._id);
        console.log("groupmessages", groupMesseges)
        // dispatch(updateConversations([groupMesseges.data]))
        unsorted.push({
          contact: groupContact,
          conversationId: group?._id,
          messages: groupMesseges.data.reverse()
        })
      }
    }

    const data = await Promise.all(unsorted.sort((a, b) => {
      console.log("in promise",a.messages)
      if(a.messages.length > 0 && b.messages.length > 0){
        const first = new Date(a.messages[a.messages.length -1].createdAt)
        const second = new Date(b.messages[b.messages.length -1].createdAt)
       return second - first
      }
      else{
        return false;
      }
    }))
    // dispatch(updateContacts(contacts.concat(groups)));

    console.log("data is",data);

    for(const item of data){
      dispatch(updateContact(item.contact));
      dispatch(updateConversationsId(item.conversationId));
      dispatch(updateConversations([item.messages]))
    }

    socketRef?.current?.emit("getUsers");

    socketRef?.current?.on("getGroupMessage", (data) => {
      console.log("get message", data)


      dispatch(updateGroupMessages(data));
      toast.info(
        `${data.message}`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

    });



    socketRef?.current?.on("getMessage", (d) => {
      console.log("message received", d);
      let sender;
        data.find((item,key) => {
          const contact = item.contact;
        if(!contact.isGroupChat && contact?.user?._id === d.sender) {
          console.log(contact.user._id, d.sender)
          sender = contact.user;
          dispatch(
            updateMessages({
              message: d
            })
          );
          
          if (!d?.callInfo?.isCall) {
            toast.info(
              `${sender.name}: ${d.message}`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }

        }
      });


    });

  };





  useEffect(() => {
    const getToken = async () => {
      const res = await getProfile();
      return res;
    }
    getToken().then((token) => {
      console.log("token is", token)
      if (token) {
        const newSocket = io("wss://backend.aivoluon.com", {
          transports: ["polling"],
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 1500,
          reconnectionAttempts: Infinity,
          autoConnect: true,
          secure: true
        });
        // newSocket.connect();
        newSocket.on("connect", async () => {
          newSocket.emit("addUser", {
            userId: token?._id,
          });

          newSocket.on("getUsers", async (data) => {
            console.log("get users data", data);
            let onlinnContacts = [];
            const token = await getProfile();
            const res = await getUser(token._id);
            const contacts = res.data.contacts;
            for (const key in contacts) {
              for (const index in data) {
                if (data[index]?.userId == contacts[key]?.user?._id) {
                  console.log("matched", contacts[index])
                  onlinnContacts.push(contacts[key]);
                }
              }
            }
            console.log("online users", onlinnContacts)
            dispatch(updateOnlineUsers(onlinnContacts));
          });

          console.log("Socket connected ", newSocket.connected, newSocket.id, "user id", token._id);
        });

        newSocket.on("disconnect", (e) => {
          console.log("Socket disconnected ", newSocket.connected, e);
          newSocket.connect();
        });


        setSocket(newSocket);
        startServices();
        console.log(newSocket);
        getMessengerData();
      }

    });

  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        startMeeting,
        me,
        localStream: myStream,
        myStream: localStream,
        endMeeting,
        streams,
        muteStream,
        unmuteStream,
        turnOffVideo,
        turnOnVideo,
        isMuted,
        isVideoTrack,
        userStreamRef,
        fetchUpdatedMessages,
        startGroupCall,
        answerGroupCall,
        isGroupCall,
        isVideoCall
      }}
    >
      {
        isGroupcallModal &&
        <GroupCallModal
          isOpen={isGroupcallModal}
          toggleCallModal={() => setIsGroupCallModal(!isGroupcallModal)}
          isVideo={isVideoCall}
          caller={remoteUser}
          user={group}
          inComming={true}
          declineCall={() => { }}
          answerCall={() => { }}
        />
      }
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
